@font-face {
  font-family: "Maven Pro";
  font-weight: bold;
  src: url("../fonts/maven-pro/MavenPro-Bold.ttf");
}
@font-face {
  font-family: "Maven Pro";
  font-weight: 500;
  src: url("../fonts/maven-pro/MavenPro-Medium.ttf");
}
@font-face {
  font-family: "Maven Pro";
  src: url("../fonts/maven-pro/MavenPro-Regular.ttf");
}
