@import "normalize";
@import "fonts";
* {
  box-sizing: border-box;
}

body {
  font-family: var(--main-font);
  font-size: 16px;
  line-height: 24px;
  color: var(--brand-darkblue);

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
}

h2 {
  font-family: var(--main-font);
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 0px;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: unset;
  color: unset;
}

input {
  border: unset;
  background: unset;
  outline: none;
}

button {
  outline: none;
  border: unset;
}

:root {
  //fonts
  --main-font: "Maven Pro", sans-serif;
  --alt-font: "SF Pro Text Regular", sans-serif;

  //brand
  --brand-blue: #2971ab;
  --brand-lightblue: #3788b0;
  --brand-lighterblue: #e4ecf0;
  --brand-darkblue: #2a333c;

  //system
  --system-white: #ffffff;
  --system-gray: #f5f7fa;

  //elevation
  --elevation-1: 0px 10px 30px 0px rgba(55, 136, 176, 0.2);
}
